import React, { useState } from 'react';
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import * as styles from "../components/indulgence.module.css"
import { StaticImage } from "gatsby-plugin-image"

import Gallery from '@browniebroke/gatsby-image-gallery'

const IndulgencePage = ({ data }) => {
        const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
        return <div >
                <Seo title="Indulgence - The Game" />
                <div className={styles.header}>
                        <a href="../" title="Developed by Jing Games" target="_blank" rel="noopener noreferrer">
                                <StaticImage style={{
                                        height: `50px`,
                                        width: `auto`,
                                }}
                                        src="../images/jing_logo_banner.png" />
                        </a>

                        <div style={{
                                right: `5px`,
                                top: `5px`,
                                width: `200px`,
                                height: `50px`,
                                // backgroundColor: `blue`, 
                                position: `absolute`
                        }}>
                                <a href="https://twitter.com/JingGames" target="_blank" rel="noopener noreferrer"
                                        title="Twitter">
                                        <StaticImage style={{
                                                width: `30px`,
                                                height: `26px`,
                                                top: `15px`,
                                                right:`20px`
                                        }}
                                                src="../images/icon_twitter.png" />
                                </a>
                                <a href="https://www.youtube.com/channel/UCVuiiPpOIZ-wdjPyEo9KppQ" target="_blank" rel="noopener noreferrer"
                                        title="Youtube"
                                        styles={{}}>
                                        <StaticImage style={{
                                                width: `30px`,
                                                height: `26px`,
                                                top: `15px`,
                                                left: `8px`
                                        }}
                                                src="../images/icon_youtube.png" />
                                </a>
                                <a href="https://trello.com/b/qfGTrsPp/indulgence-planmap" target="_blank" rel="noopener noreferrer"
                                        title="Check the future develop plan">
                                        <h4 style={{
                                                width: `90px`,
                                                top: `-5px`,
                                                left: `100px`,
                                                backgroundColor: `#3ba5fc`,
                                                color: `white`,
                                                position: `absolute`,
                                                textAlign: `center`
                                        }}>
                                                Roadmap</h4>
                                </a>
                        </div>

                </div>

                <div style={{ position: `relative`, zIndex: `1` }}>
                        <StaticImage
                                src="../images/bg_start.png" />

                        <StaticImage style={{
                                position: `absolute`,
                                zIndex: `2`,
                                width: `30%`,
                                top: `0px`,
                                left: `35%`
                        }}
                                src="../images/indulgence_title.png" />



                        <div className={styles.intro}>
                                <p style={{ maxWidth: `100%` }}>Indulgence is a never-ending story generator, inspired by <a href='http://bay12games.com/dwarves/' target="_blank" rel="noopener noreferrer">Dwarf Fortress</a>, <a href='https://en.wikipedia.org/wiki/Uncharted_Waters' target="_blank" rel="noopener noreferrer">earlier works of Uncharted Waters</a> and <a href='https://www.imdb.com/title/tt0303461/' target="_blank" rel="noopener noreferrer">Firefly</a>.
                                        <br />
                                        <br />
                                        In this game, the player acts as the captain of an intra-atmospheric craft, doing business with settlers on a distant colony planet.
                                        <br />
                                        <br />
                                        The world will evolve based on the player's actions in each game session. Some changes are insignificant, but others can have a huge impact and even create an unprecedented crisis for the captain afterwards.
                                        <br />
                                        <br />
                                        Indulgence is currently under heavy development by <a href='.' target="_blank" rel="noopener noreferrer">Mr. Jing and Mrs. Jing</a>.</p>
                        </div>

                        <div id="features"
                                style={{
                                        position: `absolute`,
                                        margin: `0 auto`,
                                        width: `60%`,
                                        padding: `var(--size-gutter)`,
                                        zIndex: `20`,
                                        top: `100%`,
                                        left: `20%`,
                                        alignSelf: `center`
                                }}>


                                <div style={{ position: `relative` }}>
                                        <StaticImage
                                                src="../images/feature_simulator.png" />
                                        <p className={styles.featureHeader}
                                                style={{
                                                        top: `12%`,
                                                        right: `8%`,
                                                        textAlign: `right`
                                                }}
                                        ><b>Captain Simulator</b></p>
                                        <p className={styles.featureContent}
                                                style={{
                                                        bottom: `4%`,
                                                        left: `52%`
                                                }}>
                                                Ah, Captain. Do you have enough money to pay the upcoming bills? Does your crew like you? Have you completed the contract you signed last month? Do you know where to find customers who interested in your cargo?
                                        </p>
                                </div>

                                <div style={{ position: `relative` }}>
                                        <StaticImage style={{
                                                maxWidth: `100%`
                                        }}
                                                src="../images/feature_world.png" />
                                        <p className={styles.featureHeader}
                                                style={{
                                                        top: `12%`,
                                                        left: `9%`
                                                }}
                                        ><b>Dynamic World</b></p>
                                        <p className={styles.featureContent}
                                                style={{
                                                        bottom: `4%`,
                                                        left: `9%`
                                                }}>
                                                Through PCG technology with the hand-crafted content, each planet is unique and interesting. The player is more than able to fully appreciate the best of emergent gameplay.
                                        </p>
                                </div>

                                <div style={{ position: `relative` }}>
                                        <StaticImage style={{
                                                maxWidth: `100%`
                                        }}
                                                src="../images/feature_endless.png" />
                                        <p className={styles.featureHeader}
                                                style={{
                                                        top: `12%`,
                                                        right: `8%`,
                                                        textAlign: `right`
                                                }}
                                        ><b>Endless Journey</b></p>
                                        <p className={styles.featureContent}
                                                style={{
                                                        bottom: `4%`,
                                                        left: `52%`
                                                }}>
                                                Captain after captain, generation after generation of settlers, the ship is still flying, bearing witness to countless stories and the pioneering history of this planet.
                                        </p>
                                </div>

                                <div style={{ position: `relative` }}>
                                        <StaticImage style={{
                                                maxWidth: `100%`
                                        }}
                                                src="../images/feature_challenge.png" />
                                        <p className={styles.featureHeader}
                                                style={{
                                                        top: `11%`,
                                                        left: `9%`
                                                }}
                                        ><b>Fun and Challenge</b></p>
                                        <p className={styles.featureContent}
                                                style={{
                                                        bottom: `4%`,
                                                        left: `9%`
                                                }}>
                                                Life is short, have more fun.
                                                <br />
                                                Sometimes you will fail, but that's okay, in a different light, failure can be fun, relaxing, just for another round.
                                        </p>
                                </div>

                                <Gallery images={images}
                                        colWidth={0.5} />


                                <div className={styles.footer}>
                                        <p style={{ width: `100%`, maxWidth: `100%` }}>
                                                Contact <a href="mailto:contact@jing.games" target="_blank" rel="noopener noreferrer" style={{ color: `white` }}>
                                                        Jing Games</a>
                                                <br />
                                                © {new Date().getFullYear()}
                                        </p>
                                </div>
                        </div>


                </div>
        </div>
}

export const pageQuery = graphql`
  query ImagesForGallery   {
    allFile(filter: {relativeDirectory: {eq: "screenshots"}}) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(width: 800, height: 450, placeholder: BLURRED)
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

export default IndulgencePage
